.footerContainer {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 15px;
    width: 100vw;
    height: 105px;
    min-height: 100px;
    position: absolute;
    align-content: center;
    justify-content: space-evenly;
    border-top: 1px solid rgb(175, 175, 175);
    background-color: #244d4d;
    bottom: 0;
    /* Alt Color */
    /* background-color: white; */
}

.footerTitle {
    display: flex;
    width: 65vw;
    margin: auto;
    margin-bottom: 0;
    margin-top: 10px;
    justify-content: center;
    color: white;
    border-bottom: 1px solid white;
    text-align: center;
    padding-bottom: 8px;
}

.name-box {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-evenly;
    margin: auto;
    margin-top: 5px;
}

.name-row {
    display: flex;
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 8px;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
}

.devLink {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    font-size: smaller;
}

.footerNames {
    margin: 0;
    color: white;
}

.line {
    width: 40%;
    color: white;
}


/* RESPONSIVE SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media screen and (min-width: 280px) and (max-width: 440px) {
    .c-box {
        width: 100%;
    }
    .line {
        width: 80%;
    }
}

@media screen and (min-width: 441px) and (max-width: 540px) {}

@media screen and (min-width: 541px) and (max-width: 700px) {}