    .mainContainer1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: center;
    }
    /* Sidebar ~~~~~~~~~~~~~~~~~~~~~~~ */
    
    .navbar1 {
        width: fit-content;
        border: none;
        z-index: 3;
    }
    
    .sideBar {
        display: flex;
        height: max-content;
        width: 15vw;
        min-width: 140px;
        max-width: 215px;
        margin-left: 30px;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        color: #244d42;
    }
    
    .sideSection {
        margin-bottom: 35px;
    }
    
    .detailTitle {
        text-align: center;
    }
    
    .sideLabel {
        font-size: small;
        align-items: center;
    }
    /* Animation ~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
    /*     
    .animated1 {
        -webkit-animation-duration: 1.2s;
        -moz-animation-duration: 1.2s;
        -ms-animation-duration: 1.2s;
        -o-animation-duration: 1.2s;
        animation-duration: 1.2s;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
    }
    
    .animated1.fast {
        -webkit-animation-duration: 800ms;
        -moz-animation-duration: 800ms;
        -ms-animation-duration: 800ms;
        -o-animation-duration: 800ms;
        animation-duration: 800ms;
    }
    
    .animated1.slow {
        -webkit-animation-duration: 1.4s;
        -moz-animation-duration: 1.4s;
        -ms-animation-duration: 1.4s;
        -o-animation-duration: 1.4s;
        animation-duration: 1.4s;
    }
    
    @-webkit-keyframes fadeInUp {
        0% {
            opacity: 0;
            -webkit-transform: translateY(20px);
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }
    }
    
    @-moz-keyframes fadeInUp {
        0% {
            opacity: 0;
            -moz-transform: translateY(20px);
        }
        100% {
            opacity: 1;
            -moz-transform: translateY(0);
        }
    }
    
    @-o-keyframes fadeInUp {
        0% {
            opacity: 0;
            -o-transform: translateY(20px);
        }
        100% {
            opacity: 1;
            -o-transform: translateY(0);
        }
    }
    
    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translateY(20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .fadeInUp1 {
        -webkit-animation-name: fadeInUp;
        -moz-animation-name: fadeInUp;
        -o-animation-name: fadeInUp;
        animation-name: fadeInUp;
    } */
    /* Item Content~~~~~~~~~~~~~~~~~~~~ */
    
    .itemContainer {
        display: flex;
        margin: 30px;
        margin-top: 0;
        width: 100%;
        max-width: 1450px;
        flex-direction: row;
        /* flex-wrap: wrap; */
    }
    
    .itemContent {
        width: 100%;
    }
    
    .itemCard {
        width: 290px;
        height: 450px;
        margin: 5px;
        margin-top: 0;
        margin-bottom: 20px;
        /* Alt Colors */
        background-color: white;
        border: 4px solid #ebebeb;
        color: #244d42;
    }
    
    .itemCard a {
        background-color: #244d4d;
        border: none;
        color: white;
        letter-spacing: 1.5px;
        padding: 10px;
        font-weight: 500;
        font-size: 15px;
        text-decoration: none;
    }
    
    .itemCard a:hover {
        background-color: #477272;
    }
    
    .itemCard:hover {
        transform: scale(1.05);
    }
    
    .itemList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin: auto;
        justify-content: space-evenly;
        padding: 0;
    }
    
    .itemUl {
        width: 100%;
        height: 100%;
        list-style-type: none;
        padding: 0;
    }
    
    .itemLi {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    
    .pictureBox1 {
        width: 100%;
        height: 50%;
    }
    
    .itemImg {
        width: 100%;
        height: 100%;
        background-size: contain;
        align-content: center;
    }
    
    .itemName {
        margin: 5px;
        max-height: 110px;
    }
    
    .itemPrice {
        font-size: larger;
        margin: 5px;
    }
    
    .moreButton {
        display: flex;
        /* width: 100px; */
        margin: auto;
        margin-bottom: 10px;
        margin-top: 10px;
        justify-content: center;
        border: 1px solid #244d42;
        color: #FFFFFF;
        align-items: flex-end;
        text-align: center;
    }
    /* RESPONSIVE SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
    
    @media screen and (max-width: 720px) {
        .mainContainer1 {
            flex-direction: column;
            font-size: x-small;
        }
        .navbar1 {
            display: block;
            background-color: white;
            width: fit-content;
            /* position: fixed; */
            top: 90px;
            font: x-small;
        }
        .sideContent {
            display: flex;
            justify-content: space-around;
        }
        .sideBarSections {
            display: flex;
            flex-direction: column;
        }
        .sideBar {
            flex-direction: row;
            max-width: 2000px;
            width: 100vw;
            margin-left: 0;
            flex-direction: column;
        }
        .navLine {
            margin-left: 5px;
            margin-right: 5px;
        }
        .sideLabel {
            font-size: x-small;
        }
        .itemContainer {
            width: 100vw;
            margin-left: 0;
        }
        .itemCard {
            width: 150px;
            height: 300px;
        }
        .moreButton {
            width: 70%;
            height: fit-content;
            font-size: x-small;
        }
        .itemCard a {
            font-weight: 500;
            font-size: 11px;
        }
    }
    
    @media screen and (min-width: 721px) and (max-width: 960px) {
        .itemCard {
            width: 200px;
            height: 500px;
        }
        .mainContainer1 {
            flex-direction: column;
            font-size: x-small;
        }
        .navbar1 {
            display: block;
            background-color: white;
            width: fit-content;
            /* position: fixed; */
            top: 90px;
            font: x-small;
        }
        .sideContent {
            display: flex;
            justify-content: space-around;
        }
        .sideBarSections {
            display: flex;
            flex-direction: column;
        }
        .sideBar {
            flex-direction: row;
            max-width: 2000px;
            width: 100vw;
            margin-left: 0;
            flex-direction: column;
        }
        .navLine {
            margin-left: 5px;
            margin-right: 5px;
        }
        .itemContainer {
            margin-left: 0;
        }
    }
    
    @media screen and (min-width: 961px) and (max-width: 1535px) {
        .itemCard {
            width: 220px;
            height: 500px;
        }
    }