.cartContainer {
    display: flex;
    margin: auto;
    width: 75vw;
    height: max-content;
    justify-content: space-between;
}

.cartHr {
    width: 100%;
}

.emptyCartBtn {
    margin: 0 auto;
}


/* Items In Cart Area ~~~~~~~~~~~~~~~~ */

.cartTitle {
    display: flex;
    margin: auto;
    padding-bottom: 25px;
    margin-bottom: 30px;
    justify-content: center;
    border-bottom: 1px solid grey;
}

.itemsInCart {
    display: flex;
    width: 60%;
    flex-direction: column;
}

.cartList {
    width: 100%;
    padding: 0;
    margin: auto;
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid rgb(199, 199, 199);
    box-shadow: 0px 8px 15px rgba(221, 221, 221, 0.459);
    height: max-content;
}

.cartCard {
    display: flex;
    margin: auto;
    /* background-color: gray; */
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    height: max-content;
}

.cartItemImg {
    display: flex;
    width: auto;
    height: 200px;
}

.itemImg1 {
    width: 100%;
    max-height: 320px;
}

.photo {
    display: flex;
    width: 100%;
    margin: 0;
}

.itemDetails {
    display: flex;
    width: 60%;
    flex-direction: column;
}

.itemTitle {
    display: flex;
}

.buttonBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.removeBtn {
    background-color: #244d4d;
    border: none;
    color: white;
    letter-spacing: 1.5px;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 40%;
}

.emptyCartContainer {
    text-align: center;
}

.emptyCartContainer button {
    width: 180px;
}


/* Cost Area ~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.costBox {
    width: 30%;
    justify-content: center;
    align-content: center;
}

.cartLine {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.buttonBox2 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.checkoutBtn {
    background-color: #244d4d;
    border: none;
    color: white;
    letter-spacing: 1.5px;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 140px;
}

.cartShopBtn {
    background-color: #244d4d;
    border: none;
    color: white;
    letter-spacing: 1.5px;
    padding: 10px;
    font-weight: 500;
    font-size: 15px;
    width: 140px;
}

.bigBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 80px;
}


/* RESPONSIVENESS ~~~~~~~~~~~~~~~~~~~~~~~ */

@media screen and (max-width: 540px) {
    .cartContainer {
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
    .itemsInCart {
        width: 85%;
        min-width: 280px;
        margin: auto;
    }
    .costBox {
        width: 85%;
        margin: auto;
        min-width: 280px;
    }
    .cartCard {
        flex-direction: column;
    }
    /* #cartImg {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    } */
    .removeBtn {
        min-width: 130px;
        margin: auto;
        margin-bottom: 20px;
    }
    .itemDetails {
        width: 100%;
    }
    .bigBox {
        flex-direction: column;
    }
    .itemImg1 {
        width: 95%;
        margin: auto;
    }
    .cartItemImg {
        width: 100%;
        display: contents;
    }
}

@media screen and (min-width:541px) and (max-width: 1000px) {
    .cartContainer {
        flex-direction: column;
        width: 100%;
        margin: auto;
    }
    .itemsInCart {
        width: 85%;
        min-width: 325px;
        margin: auto;
    }
    .costBox {
        width: 85%;
        margin: auto;
        min-width: 325px;
    }
}