.shopNav {
    display: flex;
    margin: auto;
    width: 100%;
    margin-bottom: 80px;
}

.shopList {
    display: flex;
    flex-direction: row;
    margin: auto;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1700px;
    justify-content: center;
}

.shopRoomLinks {
    text-decoration: none;
}

.roomCard {
    height: 375px;
    width: 33vw;
    max-width: 555px;
    display: flex;
    margin: 0;
    text-align: center;
    margin: auto;
    margin-top: 5px;
}

.nameBox {
    margin: auto;
    text-align: center;
    color: #244d42;
    border: 4px solid #244d42;
    background-color: rgba(255, 255, 255, 0.795);
    width: 170px;
}

.titleName {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
    z-index: 2;
}

.livingCard {
    text-align: center;
    background-image: url(./imgs/farmhouseLiving.jpg);
    background-size: cover;
    height: auto;
    width: 32vw;
    display: flex;
}

.bedroomCard {
    text-align: center;
    background-image: url(./imgs/modernBed.jpg);
    background-size: cover;
    height: auto;
    width: 32vw;
    display: flex;
}

.bathCard {
    text-align: center;
    background-image: url(./imgs/contempBathroom.jpg);
    background-size: cover;
    height: auto;
    width: 32vw;
    display: flex;
}

.kitchenCard {
    text-align: center;
    background-image: url(./imgs/bohKitchen.jpg);
    background-size: cover;
    height: auto;
    width: 32vw;
    display: flex;
}

.patioCard {
    text-align: center;
    background-image: url(./imgs/patio.jpg);
    background-size: cover;
    height: auto;
    width: 32vw;
    display: flex;
}

.allRoomCard {
    text-align: center;
    background-image: url(./imgs/house.jpg);
    background-size: cover;
    height: auto;
    width: 32vw;
    display: flex;
}

.topRow {
    display: flex;
    flex-direction: row;
}

.bottomRow {
    display: flex;
    flex-direction: row;
}


/* MODAL */

.modal__overlay {
    background-color: rgba(184, 184, 184, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: none;
}

.hidden {
    display: none;
}

.visible {
    display: flex;
    justify-content: center;
}

#redirectModal {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    padding: 16px;
    position: fixed;
    width: 500px;
    height: 250px;
    border-radius: 20px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.233);
    z-index: 9999;
}

#redirectModal p {
    margin-top: -5px;
}

#redirectModal .primaryBtn {
    width: 200px;
    margin: 0 auto;
}
  
#button.modal__close {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    color: black;
    font-size: 18px;
    border: none;
    color: slategray;
    border-radius: 15px;
}


/* RESPONSIVE SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media screen and (min-width: 280px) and (max-width: 640px) {
    html {
        min-width: 280px;
    }
    .shopNav {
        flex-direction: row;
    }
    .shopList {
        height: auto;
        flex-direction: row;
    }
    .topRow {
        flex-direction: column;
        width: 50%;
    }
    .bottomRow {
        flex-direction: column;
        width: 50%;
    }
    /* .shopRoomLinks {
        width: 50%;
    } */
    .roomCard {
        margin-right: 0;
        width: 100%;
        height: 200px;
    }
    .livingCard {
        width: 100%;
        height: 100%;
    }
    .bedroomCard {
        width: 100%;
        height: 100%;
    }
    .bathCard {
        width: 100%;
        height: 100%;
    }
    .kitchenCard {
        width: 100%;
        height: 100%;
    }
    .patioCard {
        width: 100%;
        height: 100%;
    }
    .allRoomCard {
        width: 100%;
        height: 100%;
    }
    .nameBox {
        width: 45%;
        font-size: .55rem;
    }
    #redirectModal {
        width: 250px;
    }
    #redirectModal p {
        margin-top: -12px;
    }
}

@media screen and (min-width: 641px) and (max-width: 1040px) {}

@media screen and (min-width: 1041px) and (max-width: 1179px) {}

@media screen and (min-width: 1180px) and (max-width: 1500px) {}

@media screen and (min-width: 1501px) and (max-width: 1685px) {}

@media screen and (min-width: 1686px) and (max-width: 1900px) {}

@media screen and (min-width: 1901px) {}