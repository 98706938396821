/* TOP SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.homeMainContainer {
    display: flex;
    /* background-color: bisque; */
    /* width: 80%; */
    height: 600px;
    /* margin: auto; */
    /* min-width: 700px; */
    max-width: 640px;
    align-items: center;
}

.mainBox1 {
    display: inline-block;
    float: left;
    flex-direction: column;
    background-color: #244d42;
    width: 420px;
    height: 265px;
    z-index: 2;
    margin: auto;
    position: absolute;
    vertical-align: middle;
    top: 250px;
    left: 8%;
}

.mainBox1 button {
    width: 120px;
    margin-left: 10px;
    letter-spacing: 1.5px;
    font-weight: 500;
    height: 40px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    margin-bottom: 20px;
}


.mainBox1 button:hover {
    background-color: white;
    color: #244d4d;
}
.mainBox2 {
    margin: auto;
    background-color: darkgrey;
    width: 700px;
    height: 325px;
    z-index: 1;
    position: absolute;
    left: 30%;
    top: 150px;
}

.homepageImg2 {
    width: 700px;
}

.greetingHeading {
    padding: 10px;
    margin: 0;
    color: white;
    font-size: 2.5rem;
}

.greetingContent {
    padding: 10px;
    padding-top: 0;
    margin: 0;
    color: white;
}

.startButton {
    width: 100px;
    margin-left: 10px;
    height: 30px;
    border: 1px solid white;
    background-color: transparent;
    color: white;
    
}

.startButton:hover {
    background-color: white;
    color: #244d4d;
}


/* HOW IT WORKS SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.lowerTitle {
    margin: auto;
    display: flex;
}

.lowerTitleContent {
    margin: auto;
}

.lowerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: fit-content;
    min-height: 500px;
    margin-bottom: 10px;
}

.lowerBoxes {
    display: flex;
    flex-direction: column;
    width: 25%;
    max-width: 300px;
    height: fit-content;
    justify-content: space-between;
}

.icons {
    display: flex;
    width: 30%;
    margin: auto;
    margin-bottom:20px;
}

.subContent {
    margin: 10px;
    text-align: left;
}

.subTitles {
    font-family: 'Open Sans', sans-serif;
    font-size:18px;
    margin:10px;
}


/* END PAGE SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.endPageContainer {
    display: flex;
    margin: auto;
    margin-top: 20px;
    width: 100%;
    height: fit-content;
    min-height: 400px;
    /* background-color: green; */
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 80px;
}

.pictureBox {
    width: 40%;
    margin: auto;
}

.greenChairs {
    width: 95%;
    margin: 15px;
}

.backdropBox {
    background-color: #244d42;
    width: 95%;
    height: 394px;
}

.endTextBox {
    width: 40%;
    padding-left: 20px;
    padding-right: 20px;
    align-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
}

.endTextH1 {
    text-align: center;
    margin: auto;
}

.endTextP {
    text-align: left;
    margin: auto;
    margin-top: 25px;
}

.learnButton {
    width: 200px;
    margin: auto;
    justify-content: center;
    height: 50px;
    border: 1px solid white;
    background-color: #244d42;
    color: white;
    margin-top: 25px;
    letter-spacing: 1.5px;
    font-weight: 500;
    font-size: 15px;
}

.learnButton:hover {
    background-color: #477272;
}


/* RESPONSIVE SECTION ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media screen and (min-width: 280px) and (max-width: 640px) {
    html {
        min-width: 280px;
    }
    .homeMainContainer {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        top: 0;
        min-width: 375px;
        height: auto;
    }
    .mainBox1 {
        display: flex;
        position: relative;
        left: 0;
        top: 0;
        width: 100%;
        text-align: center;
        padding-bottom: 15px;
        height: 275px;
     
    }
    .mainBox2 {
        display: flex;
        width: 100vw;
        height: 455px;
        position: relative;
        margin: 0;
        top: 0;
        left: 0;
    }
    .homepageImg2 {
        position: absolute;
        min-width: 500px;
        min-height: 440px;
    }
    .startButton {
        margin: auto;
        height: 100%;
        width: 30%;
        border: 1px solid white;
        background-color: transparent;
        color: white;
    }
    .mainBox1 button {
        margin: auto;
        letter-spacing: 1.5px;
        padding: 10px;
        font-weight: 500;
        font-size: 15px;
        border: 1px solid white;
        background-color: transparent;
        color: white;
     
    }

    .lowerTitle {
        height: 100px;
    }
    .lowerContainer {
        justify-content: center;
        flex-direction: column;
        margin: auto;
    }
    .lowerBoxes {
        width: 85%;
        height: fit-content;
        margin: auto;
        justify-content: center;
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid gray;
    }
    .endPageContainer {
        display: flex;
        margin: auto;
        margin-top: 25px;
        margin-bottom: 80px;
        width: 100%;
        height: fit-content;
        /* background-color: green; */
        flex-direction: column;
        justify-content: space-evenly;
    }
    .pictureBox {
        width: 95%;
        margin: auto;
    }
    .endTextBox {
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        align-content: center;
        align-items: center;
        margin: auto;
        text-align: center;
    }
    .greetingHeading {
        padding: 0;
        height: auto;
    }
    .greetingContent {
        width: 75%;
        text-align: center;
        margin: auto;
    }
}

@media screen and (min-width: 641px) and (max-width: 1040px) {
    .homeMainContainer {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        top: 0;
        min-width: 375px;
        height: auto;
    }
    .mainBox1 {
        display: flex;
        position: relative;
        left: 0;
        top: 0;
        width: 100vw;
        text-align: center;
        padding-bottom: 15px;
        margin-bottom: 25px;
    }
    .mainBox2 {
        display: flex;
        background-color: tomato;
        width: 100%;
        height: 455px;
        position: relative;
        margin: 0;
        top: 0;
        left: 0;
    }
    .homepageImg2 {
        position: absolute;
        min-width: 100vw;
        min-height: 440px;
    }
    .startButton {
        margin: auto;
        height: 100%;
        width: 30%;
        border: 1px solid white;
        background-color: transparent;
        color: white;
    }

    .mainBox1 button {
        margin: auto;
        letter-spacing: 1.5px;
        padding: 10px;
        width: 150px;
        height: 40px;
        font-weight: 500;
        font-size: 15px;
        border: 1px solid white;
        background-color: transparent;
        color: white;
    }
    .lowerTitle {
        height: 100px;
    }
    .lowerContainer {
        justify-content: center;
        /* flex-direction: column; */
        margin: auto;
    }
    .lowerBoxes {
        width: 85%;
        margin: auto;
        justify-content: center;
    }
    .greetingContent {
        width: 75%;
        text-align: center;
        margin: auto;
    }
    .endPageContainer {
        display: flex;
        margin: auto;
        margin-top: 80px;
        margin-bottom: 80px;
        width: 100%;
        height: fit-content;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .pictureBox {
        width: 95%;
        margin: auto;
    }
    .endTextBox {
        width: 50%;
        padding-left: 20px;
        padding-right: 20px;
        align-content: center;
        align-items: center;
        margin: auto;
        text-align: center;
    }
}

@media screen and (min-width: 1041px) and (max-width: 1179px) {
    .greetingContent {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1180px) and (max-width: 1500px) {
    .mainBox1 {
        left: 12%;
    }
    .mainBox2 {
        left: 35%;
    }
    .greetingContent {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1685px) {
    .mainBox1 {
        left: 20%;
    }
    .mainBox2 {
        left: 42%;
    }
    .greetingContent {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1686px) and (max-width: 1900px) {
    .mainBox1 {
        left: 23%;
    }
    .mainBox2 {
        left: 43%;
    }
    .greetingContent {
        font-size: 1rem;
    }
}

@media screen and (min-width: 1901px) {
    .mainBox1 {
        left: 25%;
    }
    .mainBox2 {
        left: 45%;
    }
    .greetingContent {
        font-size: 1rem;
    }
}