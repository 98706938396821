/* Button */

a {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    transition: all 200ms ease;
    -webkit-transform: translate3d(0, 0, 0);
    /* Webkit Hardware Acceleration */
    -webkit-backface-visibility: hidden;
    /* Animation fix */
}

a,
a:visited,
a:active {
    text-decoration: none;
}

.btn {
    display: block;
    margin: 30px auto;
    padding: 10px;
    border: 2px solid #244d4d;
    text-transform: uppercase;
    font-weight: bold;
    background: #244d4d;
    color: #fff;
    width: 60%;
}

.btn:hover {
    background: transparent;
    color: #244d4d;
}

.timer {
    font-size: 16px;
    font-size: 1rem;
}


/* Animation */

.animated {
    -webkit-animation-duration: 1.2s;
    -moz-animation-duration: 1.2s;
    -ms-animation-duration: 1.2s;
    -o-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
}

.animated.fast {
    -webkit-animation-duration: 800ms;
    -moz-animation-duration: 800ms;
    -ms-animation-duration: 800ms;
    -o-animation-duration: 800ms;
    animation-duration: 800ms;
}

.animated.slow {
    -webkit-animation-duration: 1.4s;
    -moz-animation-duration: 1.4s;
    -ms-animation-duration: 1.4s;
    -o-animation-duration: 1.4s;
    animation-duration: 1.4s;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }
    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }
    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


/* Layout: center box */

.logo-box {
    text-align: center;
    background-color: white;
    padding: 30px;
}

.master-wrap {
    width: 100%;
}

.lead {
    margin-top: 10px;
    margin-bottom: 10px;
}

.truckIcon {
    height: 128px;
    margin: auto;
    width: 100px;
}


/* Desktop only */


/* RESPONSIVE ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

@media (min-width: 1px) and (max-width: 412px) {
    #logo-box {
        text-align: center;
        top: 10%;
        left: 0;
        width: 100vw;
        max-width: 400px;
        height: auto;
        min-height: 219px;
        font-size: 20px;
        background-color: white;
        border: 3px solid #999;
        box-shadow: 6px 6px 0px #244d4d;
        position: fixed;
    }
    h1 {
        font-size: 36px;
    }
    .iconModal {
        margin-top: 20px;
        padding: 20px;
    }
}

@media (min-width: 412px) and (max-width: 500px) {
    #logo-box {
        text-align: center;
        top: 10%;
        left: 2%;
        width: 400px;
        max-width: 400px;
        height: auto;
        min-height: 219px;
        font-size: 20px;
        background-color: white;
        border: 3px solid #999;
        box-shadow: 6px 6px 0px #244d4d;
        position: fixed;
    }
    h1 {
        font-size: 36px;
    }
    .iconModal {
        margin-top: 20px;
        padding: 20px;
    }
}

@media (min-width: 500px) and (max-width: 960px) {
    #logo-box {
        text-align: center;
        top: 15%;
        width: 80%;
        max-width: 400px;
        margin-left: -205px;
        height: auto;
        min-height: 219px;
        font-size: 20px;
        background-color: white;
        border: 3px solid #999;
        box-shadow: 6px 6px 0px #244d4d;
        position: fixed;
        z-index: 20;
    }
    h1 {
        font-size: 36px;
    }
    .iconModal {
        margin-top: 20px;
        padding: 20px;
    }
}

@media (min-width: 961px) {
    #logo-box {
        text-align: center;
        top: 15%;
        width: 80%;
        max-width: 400px;
        margin-left: -205px;
        height: auto;
        min-height: 219px;
        font-size: 20px;
        background-color: white;
        border: 3px solid #999;
        box-shadow: 6px 6px 0px #244d4d;
        position: fixed;
        z-index: 20;
    }
    h1 {
        font-size: 36px;
    }
}