.logInOut {
    border: none;
    background-color: transparent;
    color: #636363;
    padding: 0;
    font-size: 16px;
}

.logInOut:hover {
    color: rgb(148, 148, 148);
}