.wrapper {
  width: 1060px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
}

.quizContainer {
  width: 1060px;
}

.row,
.budgetRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}

.col {
  width: 200px;
}

.carouselSlide {
  background-color: #244d4d;
  color: white;
  padding: 10px;
  margin-bottom: 50px;
}

.carouselSlide input {
  box-shadow: none;
}

.secondaryBtn {
  background-color: white;
  border: none;
  color: #244d4d;
  letter-spacing: 1.5px;
  padding: 10px 25px;
  font-weight: 500;
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.secondaryBtn:hover {
  background-color: rgb(233, 233, 233);
}

/* BUDGET */

.budgetRow input {
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
  display: none;
}

.budgetIcon:hover {
  filter: brightness(1) grayscale(0.2) opacity(0.9);
}

.budgetRow input:checked + .budgetIcon {
  border: 2px solid white;
}

.firstTier {
  background: url("./imgs/shoppingbag.png");
}

.secondTier {
  background: url("./imgs/shoppingBag2.png");
}

.thirdTier {
  background: url("./imgs/shoppingBag3.png");
}

.budgetIcon {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  padding: 15px;
  display: inline-block;
}

/* STYLE SELECTION*/
.bohemian {
  background: url("./imgs/bohemian.jpg");
}

.farmhouse {
  background: url("./imgs/farmhouse.jpg");
}

.contemporary {
  background: url("./imgs/contemporary.jpg");
}

.modern {
  background: url("./imgs/modern.jpg");
}

.styleCol {
  margin-left: 50px;
}

.styleImg {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
  width: 500px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  display: block;
  filter: brightness(1) grayscale(0.6) opacity(0.8);
}

.styleCol input {
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
  display: none;
}

.styleImg:hover {
  filter: brightness(1) grayscale(0.2) opacity(0.9);
}

.styleCol input:checked + .styleImg {
  filter: none;
}

/* ROOMS */

.roomContainer {
  display: flex;
  justify-content: center;
}

.roomCol {
  width: 300px;
}

.roomIcon {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  padding: 15px;
  display: inline-block;
}

.roomIcon:hover {
  filter: brightness(1) grayscale(0.6) opacity(0.8);
  border: 2px solid white;
}

.roomCol input:checked + .roomIcon {
  border: 2px solid white;
}

.livingRoom {
  background: url("./imgs/livingRoom.png");
}

.bedroom {
  background: url("./imgs/bedroom.png");
}

.bathroom {
  background: url("./imgs/bathroom.png");
}

.kitchen {
  background: url("./imgs/kitchen.png");
}

.patio {
  background: url("./imgs/patio.png");
}

.roomCol input {
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
}

/* COLOR SELECTION + HIDDEN DIVS */

.hidden {
  height: 0px;
  width: 0px;
}

.active {
  display: inline-block;
}

.colorRow {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.red {
  background: url("./imgs/red.png");
}

.blue {
  background: url("./imgs/blue.png");
}

.black {
  background: url("./imgs/black.png");
}

.white {
  background: url("./imgs/white.png");
}

.yellow {
  background: url("./imgs/yellow.png");
}

.green {
  background: url("./imgs/green.png");
}

.purple {
  background: url("./imgs/purple.png");
}

.orange {
  background: url("./imgs/orange.png");
}

.gray {
  background: url("./imgs/gray.png");
}

.brown {
  background: url("./imgs/brown.png");
}

.colorIcon {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 50px;
  padding: 15px;
  display: inline-block;
}

.colorRow input {
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
}

.colorCol {
  margin-right: 25px;
}

.colorIcon:hover {
  border: 2px solid white;
}

.colorCol input:checked + .colorIcon {
  border: 2px solid white;
}

/* AVOID ROW AND STYLES */
.avoidRow {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.avoidRow label {
  margin: 0 auto !important;
}

.avoidCol .avoidIcon {
  background-size: contain;
  background-repeat: no-repeat;
  padding: 15px;
  display: block;
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.avoidCol input {
  display: none;
}

.avoidCol {
  margin-right: 20px;
}

.anything {background: url("./imgs/anything.png")}
.anything-active {
  background: 
    url("./imgs/green-check.png"), 
    url("./imgs/anything.png");
}

.pillow {background: url("./imgs/pillow.png")}
.pillow-active {
  background:
    url("./imgs/red_x.png"),
    url("./imgs/pillow.png")
    
}

.lamp {background: url("./imgs/lamp.png")}
.lamp-active {
  background: 
    url("./imgs/red_x.png"),
    url("./imgs/lamp.png")
}

.art {background: url("./imgs/art.png")}
.art-active {
  background: 
    url("./imgs/red_x.png"),
    url("./imgs/art.png")
}

.decor {background: url("./imgs/decor.png")}
.decor-active {
  background: 
    url("./imgs/red_x.png"),
    url("./imgs/decor.png")
}

.linens {background: url("./imgs/linens.png")}
.linens-active {
  background: 
    url("./imgs/red_x.png"),
    url("./imgs/linens.png")
}

.storage {background: url("./imgs/storage.png")}
.storage-active {
  background: 
    url("./imgs/red_x.png"),
    url("./imgs/storage.png")
}

.serverware {background: url("./imgs/serverware.png")}
.serverware-active {
  background:
    url("./imgs/red_x.png"),
    url("./imgs/serverware.png")
}

.utensils {background: url("./imgs/utensils.png")}
.utensils-active {
  background:
    url("./imgs/red_x.png"),
    url("./imgs/utensils.png")
}

/* MEDIA QUERIES */

@media screen and (max-width: 960px) {
  .carouselSlide {
    margin-bottom: 30px;
  }

  .quizContainer {
    width: 320px;
    margin: 0 auto;
  }

  .row {
    flex-direction: column;
  }

  .budgetIcon {
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }

  .styleCol {
    margin: 0 auto;
  }

  .styleImg {
    height: 200px;
    width: 250px;
    margin: 0 auto;
  }

  .roomIcon {
    height: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 55px;
  }

  .colorRow {
    flex-wrap: wrap;
    margin-left: 10px;
  }

  .avoidCol .avoidIcon {
    height: 50px;
    width: 50px;
  }

  .avoidCol {
    margin-right: 10px;
    width: 100px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1124px) {
  .quizContainer {
    width: 600px;
  }

  .row {
    flex-direction: row;
  }

  .styleCol {
    margin: 0;
    padding: 5px;
  }

  .roomCol {
    width: 200px;
  }
}


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .quizContainer {
    width: 800px;
  }

  .styleImg {
    height: 200px;
    width: 310px;
  }

  .styleCol {
    margin: 0;
    padding: 5px;
  }
}

/* GALAXY FOLD */

@media (max-width: 320px) {
  body {
    font-size: .85rem;
  }

  .quizContainer {
    width: 250px;
  }
  .styleImg {
    height: 100px;
    width: 150px;
  }

  .roomIcon {
    height: 45px;
    width: 45px;
  }

  .colorIcon {
    height: 35px;
  }

  .colorCol {
    margin-right: 0px;
  }

  .avoidCol {
    margin-right: 0px;
  }

  .avoidCol .avoidIcon {
    height: 80px;
  }
}
